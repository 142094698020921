<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Thick </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="thick"
                                    :items="thicks"
                                    default="" item-value="thick" item-text="thick"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                    @change="(event) => getWidth(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Width </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    default="" item-value="width" item-text="width"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                ></v-autocomplete>
                            </v-col> -->

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period From </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        type="month"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period To </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        type="month"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-5 border-12" style="padding: 19px;" color="info" elevation="2" small @click="submit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="9" xs="12" sm="12" md="9" lg="9" class="ml-3 mr-2 mb-6">
                <v-row>
                    <v-col class="col-12" sm="12" md="12">
                        <v-card id="weight" outlined height="303">
                            <v-card-text>
                            <div class="d-flex w-100">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon> Off Gauge Qty (Ton) </h6>
                                <v-spacer></v-spacer>
                                <h6 class="m-0 text-black"> Rata-rata berat ID off gauge {{ last_year }} = {{ avgOffGaugeLy }} (Ton) </h6>
                            </div>
                            
                            <div id="container1" style="height: 279px; width: 100%;"></div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-6">
                    <!-- table disini -->
                    <!-- <v-col class="col-12" sm="12" md="6"> -->
                        <v-card id="percent" outlined height="303">
                            <v-card-text>
                                <div class="d-flex w-100">
                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon> Off Gauge Percent </h6>
                                    <v-spacer></v-spacer>
                                    <h6 class="m-0 text-black"> Rata-rata percent ID off gauge {{ last_year }} = {{ avgPcrLy }} % </h6>
                                </div>
                                
                                <div id="container2" style="height: 279px; width: 100%;"></div>
                            </v-card-text>
                        </v-card>
                    <!-- </v-col> -->
                </v-row>
            </v-col>

            <v-col cols="2" xs="12" sm="12" md="2" lg="2" class="ml-4">
                <!-- 3 col kanan -->
                <v-row class="mb-2">
                    <v-card id="kotak1" outlined height="200">
                        <v-card-text>
                            <p class="m-0 fw-bold text-black text-wrap">Rata-rata berat ID off gauge di CRM {{ lastPeriode }}: {{ lastPeriodeAveragePl }} Kg ({{ lastPeriodeTotalCoil }} Coil) </p>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row class="mb-2">
                    <v-card id="kotak2" outlined height="200">
                        <v-card-text>
                            <p class="m-0 fw-bold text-black text-wrap">Rata-rata berat dummy welding Head (HRC) : {{ avgWelding }} Kg ( {{ lengthWelding }} meter ) </p>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row>
                    <v-card id="kotak3" outlined height="200">
                        <v-card-text>
                            <p class="m-0 fw-bold text-black text-wrap">Rata-rata berat real off gauge {{ lastPeriode }}: {{ realOffGauge }} Kg</p>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
        
        <v-row class="mb-2">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="170"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >
                                    <!-- <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile" style="font-size: large;">Table</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchItem" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="openDialog('new', [])"><v-icon>mdi-plus</v-icon> New PMT </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script>
import { offGaugeAnalysis } from "../../../backend-api/sm/production/off_gauge_analysis";
import { formatDate } from "../../../backend-api/util/format_date";

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Production',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Off Gauge Analysis',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            thick:'',
            thicks:[],
            width:'',
            widths:[],
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            searchItem: '',
            header:[],
            headersColumn:{},
            data:[],
            showDataDetail:true,
            last_year:"",
            lastPeriode:"",
            lastPeriodeAveragePl:0,
            lastPeriodeTotalCoil:0,
            avgWelding: 0,
            lengthWelding: 0,
            realOffGauge: 0,
            searchItem: '',
            header:[],
            data:[],
            avgOffGaugeLy:0,
            avgPcrLy:0,
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getThick()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getThick(){
            let reqBody = {
                "commodity_id" : ['HRP', 'HRPO'],
                "active_flag" : 'Y'
            }
            const respData = await offGaugeAnalysis.fetchThick('', reqBody)
            if (respData.status === 200) {
                this.thicks = respData.data.data
            }
        },
        async getWidth(event){
            let reqBody = {
                "commodity_id" : ['HRP', 'HRPO'],
                "active_flag" : 'Y',
                'thick' : this.thick
            }
            const respData = await offGaugeAnalysis.fetchWidth('', reqBody)
            if (respData.status === 200) {
                this.widths = respData.data.data
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)
            let startPeriode = this.startDate.replace("-", "")
            let endPeriode = this.endDate.replace("-", "")
            this.last_year = this.endDate.replace("-", "").substring(0,4)-1
            let reqBody = {
                'thick' : this.thick,
                'width' : this.width,
                'start_date' : startPeriode,
                'end_date' : endPeriode,
                'last_year': this.last_year
            }
            const respData = await offGaugeAnalysis.fetchIndex('', reqBody)
            if (respData.status === 200) {
                let datas = respData.data.data
                let chartDatas = datas.chart
                let picklingData = datas.pickling
                let rolling = datas.rollingYear

                // Chart Creation
                const transformedData = this.splitData(chartDatas)
                const transformedDataPcr = this.splitDataPercent(chartDatas)
                const dataChart = this.createChartData(transformedData);
                const dataChartPercent = this.createChartData(transformedDataPcr);
                // Tabel Creation
                this.data = this.createTableData(chartDatas)
                this.header = this.createHeaderTable(this.data, 'bulan', 'bulan')
                // console.log('header')
                // console.log(this.header)
                this.$store.dispatch('setOverlay', false)
                this.getChart(dataChart, "container1")
                this.getChart(dataChartPercent, "container2")
                // kotak2 creation
                const maxPeriod = chartDatas.reduce((max, item) => item.period > max ? item.period : max, chartDatas[0].period);
                let foundObject = chartDatas.find(item => item.period === maxPeriod);
                this.lastPeriode = formatDate.formatPeriod(foundObject.period)
                this.lastPeriodeAveragePl = this.$store.getters.convertToCurrencyUs(foundObject.average_pl)
                this.lastPeriodeTotalCoil = this.$store.getters.convertToCurrencyUs(foundObject.total_coil_id)
                this.avgWelding = this.$store.getters.convertToCurrencyUs(picklingData.avg_welding)
                this.lengthWelding = this.$store.getters.convertToCurrencyUs(picklingData.length_welding)
                this.realOffGauge = this.$store.getters.convertToCurrencyUs(foundObject.average_pl - picklingData.avg_welding)
                this.avgOffGaugeLy = this.$store.getters.convertToCurrencyUs(rolling.avg_off_gauge)
                this.avgPcrLy = this.$store.getters.convertToCurrencyUs(rolling.percent_off_gauge)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Failed to retrieve data',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        splitData(data){
            const transformedData = data.map((item, index) => ({
                x: index + 1,
                y: Number(item.wgt_scrap),
                label: item.period
            }));

            return transformedData;
        },

        splitDataPercent(data){
            const transformedData = data.map((item, index) => ({
                x: index + 1,
                y: Number(item.PCR),
                label: item.period
            }));

            return transformedData;
        },

        createChartData(data){
            let result = []
            result.push({
                type: 'column',
                indexLabel: "{y}",
                indexLabelFontSize: 12,
                // indexLabelFontColor: "#FFF",
                name: "",
                showInLegend: true,
                visible: true,
                indexLabelPlacement: "inside",
                indexLabelOrientation: "vertical",
                yValueFormatString: "#,###.##",
                dataPoints: data.map(point => ({
                    label: point.label,
                    y: point.y,
                    // click: () => this.onClickChart(point.label)
                }))
            })
            return result;
        },

        createTableData(data) {
            // Initialize the transformed structure
            let transformed = [
                { "bulan": "Berat (Ton)" },
                { "bulan": "%" },
                { "bulan": "Rata-rata (Kg)" }
            ];

            // Populate the new structure
            data.forEach(item => {
                transformed[0][item.period] = this.$store.getters.convertToCurrencyUs(parseFloat(item.wgt_scrap))
                transformed[1][item.period] = this.$store.getters.convertToCurrencyUs(parseFloat(item.PCR))
                transformed[2][item.period] = this.$store.getters.convertToCurrencyUs(parseFloat(item.average_pl))
            });

            return transformed;
        },

        createHeaderTable(localData, mainLabel, mainValue){
            let yValues = [];
            localData.forEach(entry => {
                for (const key in entry) {
                    if (key !== mainLabel && !yValues.includes(key)) {
                        yValues.push(key);
                    }
                }
            });
            let header = [
                { text: mainLabel, value: mainValue, align: 'start' }
            ];

            yValues.forEach(yValue => {
                header.push({ text: yValue, value: yValue });
            });

            return header
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(datas, container){
            var self = this;
            console.log(datas)

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Ton",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "center",
                    verticalAlign: "bottom",
                },
                data: datas
            });
            chart.render();
        },
    },
}
</script>
<style scoped>

</style>