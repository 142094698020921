export const formatDate = (() =>{
    const formatPeriod = ((period) => {
        const year = period.substring(0, 4);
        const month = period.substring(4, 6) - 1;      
        const date = new Date(year, month);
        const formatted = date.toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        });
        return formatted;
    })

    return {formatPeriod};
})()

