import {backendApi} from "../../backend-api-sr"

export const offGaugeAnalysis = (() =>{
    const fetchThick = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/off-gauge-analysis/filter-thick'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchWidth = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/off-gauge-analysis/filter-width'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/off-gauge-analysis'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchThick, fetchWidth, fetchIndex};
})()